'use client';

import { AppRouterCacheProvider } from '@mui/material-nextjs/v15-appRouter';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';
import { theme } from '@packages/themes';
import type { Device } from '@packages/themes/src/default';

export const ThemeRegistry = ({ children, device }: PropsWithChildren<{ device: Device }>) => {
  const memoizedTheme = useMemo(() => theme(device), [device]);

  return (
    <AppRouterCacheProvider>
      <ThemeProvider theme={memoizedTheme}>
        <meta name="theme-color" content={memoizedTheme.palette.primary.main} />
        <CssBaseline />
        {children}
      </ThemeProvider>
    </AppRouterCacheProvider>
  );
};
