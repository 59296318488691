'use client';

import type { FC, ReactElement, ReactNode } from 'react';
import { forwardRef, useEffect } from 'react';
import { Dialog, type DialogProps, Slide } from '@mui/material';
import type { TransitionProps } from '@mui/material/transitions';

import { type GTMEventGlycerinDisplayOverlay } from '@packages/tracking/src/types/events';
import { type GlycerinDisplayBasePayload } from '@packages/tracking/src/types/payloads';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';

import { useDeviceType } from '../../hooks/useDeviceType/useDeviceType';

import { DialogHeader } from '../DialogHeader';
import { DialogFooter } from '../DialogFooter';

export type DialogPresetProps = Omit<DialogProps, 'title' | 'open' | 'onClose' | 'children'> & {
  /** Title that should be displayed in the dialog header */
  title?: string;
  /** Determines if the dialog should be shown */
  open: boolean;
  /** Callback fired when the dialog requests to return to the previous page
   *
   * Supplying a callback here will cause the dialog to have a different header (with a back button) and appearance transition (sliding in from the left)
   */
  onBack?: () => void;
  /** Callback fired when the dialog requests to close the containing dialog */
  onClose?: () => void;
  /** dialog content */
  children?: ReactNode;
  /** Actions for the dialog, one or more buttons
   *
   * first should be color="primary", second should be variant="text", as a React.Fragment without any additional wrappers */
  buttons?: ReactNode;
  /** Determines if the dialog buttons should get position: sticky to be always visible at bottom of screen */
  stickyFooter?: boolean;
  /** Apply a workaround for a graphical glitch with the close button documented in https://github.com/mui/material-ui/issues/12879 */
  applyCloseButtonPaddingWorkaround?: boolean;
  displayTrackingProps?: GlycerinDisplayBasePayload;
};

const NavigationTransition = forwardRef(
  (
    props: TransitionProps & {
      children: ReactElement<any>;
    },
    ref: React.Ref<unknown>,
  ) => <Slide direction="right" ref={ref} {...props} />,
);

/**
 * A modal dialog implementing the visual layout of the empiriecom design system
 * */
export const DialogPreset: FC<DialogPresetProps> = ({
  title,
  open,
  onBack,
  onClose,
  children,
  buttons,
  applyCloseButtonPaddingWorkaround,
  maxWidth = 'lg',
  stickyFooter = false,
  displayTrackingProps,
  ...additionalProps
}) => {
  const { isDesktop } = useDeviceType();

  const dispatchGTMEvent = useTracking();
  const { category, custom, detail, label, placement, variantIds } = displayTrackingProps ?? {};

  useEffect(() => {
    if (open) {
      dispatchGTMEvent<GTMEventGlycerinDisplayOverlay>({
        event: 'DisplayOverlay',
        DisplayOverlayData: {
          category: category ?? 'display-overlay',
          custom,
          detail,
          label: label ?? title,
          placement,
          variantIds,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const fullScreen = !isDesktop;
  const transition = !isDesktop && onBack ? NavigationTransition : undefined;

  const { PaperProps, TransitionComponent, ...remainingProps } = additionalProps;

  return (
    <Dialog
      title={title}
      maxWidth={maxWidth}
      fullWidth
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      TransitionComponent={TransitionComponent || transition}
      PaperProps={{ square: true, ...PaperProps }}
      {...remainingProps}
    >
      <DialogHeader
        title={title}
        onBack={onBack}
        onClose={onClose}
        applyCloseButtonPaddingWorkaround={applyCloseButtonPaddingWorkaround}
      />

      {children}

      {buttons && <DialogFooter sticky={stickyFooter}>{buttons}</DialogFooter>}
    </Dialog>
  );
};
