'use client';

import dynamic from 'next/dynamic';
import { useSearchParams } from 'next/navigation';

const DynamicTestPanel = dynamic(() => import('@packages/modules/src/TestPanel/TestPanel'), {
  ssr: false,
});

export const TestPanelLoader = () => {
  const searchParams = useSearchParams();

  return (
    typeof window !== 'undefined' &&
    window.location.href.indexOf('empirie.dev') > 0 &&
    !searchParams?.get('skipTP') && <DynamicTestPanel />
  );
};
