'use client';

import { cn } from '../../utils/cn/cn';
import { usePageTransition } from '../../providers/PageTransitionProvider/usePageTransition';

/*
 * PageProgressBar is a component that displays a progress bar (CSS animation) when a page transition starts and unmounts it when the transition is completed.
 */
export const PageProgressBar = ({ className }: { className?: string }) => {
  const pageTransition = usePageTransition();

  return (
    pageTransition?.loading && (
      // there is no label for this "control"
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <div
        role="progressbar"
        className={cn(
          'bg-color-grey-light fixed inset-x-0 top-0 z-10 h-0.5 overflow-hidden',
          className,
        )}
      >
        <div className="origin-left-right bg-color-primary animate-progress h-full w-full" />
      </div>
    )
  );
};
