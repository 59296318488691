'use client';

import { useMemo } from 'react';
import { cacheExchange } from '@urql/exchange-graphcache';
import { UrqlProvider, ssrExchange, fetchExchange, createClient } from '@urql/next';
import { keys } from './cacheKeySettings';

/**
 * The urql client provided by the GqlProvider should only be used for data which is mutable by the user to use the benefits of the graphcache! (e.g. basket or wishlist)
 * When using this client, you can query data with hooks from the `@urql/next` package to enable SSR, streaming and suspense functionality. Keep in mind that the fetched data is streamed from the react client on the server and injected into the graphcache in the browser-client. When browser-client-only behavior is desired, then use the hooks from the `urql` package.
 */
export const GqlProviderWithGraphcache = ({ children }: React.PropsWithChildren) => {
  const [client, ssr] = useMemo(() => {
    const ssrEx = ssrExchange();
    const urqlClient = createClient({
      url:
        process.env.GQL_GATEWAY ??
        `${typeof window === 'undefined' ? process.env.SERVER_HOST : ''}/api/gateway/`,
      exchanges: [cacheExchange({ keys }), ssrEx, fetchExchange],
      suspense: true,
    });
    return [urqlClient, ssrEx];
  }, []);

  return (
    <UrqlProvider client={client} ssr={ssr}>
      {children}
    </UrqlProvider>
  );
};
