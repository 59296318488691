'use client';

import { Box } from '@packages/shared';
import { useIntersectionObserver } from '@packages/shared/src/hooks/useIntersectionObserver/useIntersectionObserver';
import { useIsBot } from '@packages/tracking/src/hooks/useIsBot/useIsBot';
import type { ReactNode } from 'react';
import { useRef } from 'react';

export const LazyRender = ({
  children,
  fallback,
  options = {},
}: {
  children: ReactNode;
  fallback?: ReactNode;
  options?: IntersectionObserverInit;
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { isIntersecting } = useIntersectionObserver(wrapperRef, options);
  const isBot = useIsBot();

  return (
    // eslint-disable-next-line no-nested-ternary
    <Box ref={wrapperRef}>{isBot ? children : isIntersecting ? children : (fallback ?? null)}</Box>
  );
};
