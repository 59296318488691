import type { JSX } from 'react';
/* @jsxImportSource react */
/**
 * This component is used to prevent layout shifts during the loading state.
 * It provides a placeholder that matches the size and shape of the content
 * that will eventually be loaded, ensuring that the layout remains stable
 * even before the actual content is ready to be displayed.
 *
 * @returns {JSX.Element} The rendered FooterFallbackLoading component.
 */
export const FooterFallbackLoading = (): JSX.Element => (
  <div className="bg-color-grey-light w-full animate-pulse">
    <div className="mx-auto min-h-[662px] max-w-screen-xl md:min-h-[620px]" />
  </div>
);
