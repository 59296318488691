/* @jsxImportSource react */

import { cn } from '@packages/shared/src/utils/cn/cn';

export const SlotLoading = ({ className }: { className?: string }) => (
  <div className={cn('flex animate-pulse flex-col items-center py-1', className)}>
    <div className="bg-color-grey-light h-[32px] w-[32px] rounded-md" />
    <div className="h-[var(--body3-lineheight)] w-[64px] pt-0.5 max-lg:hidden">
      <div className="bg-color-grey-light h-full rounded-sm" />
    </div>
  </div>
);
