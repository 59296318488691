'use client';

import dynamic from 'next/dynamic';

export const DynamicRedirectOverlay = dynamic(
  /* webpackChunkName: 'RedirectOverlay' */
  () => import('./RedirectOverlay'),
  {
    ssr: false,
  },
);
