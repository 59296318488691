import config from '@packages/config';
import { getStaticBucketUrl, UrlType } from './apiUrl.staticPage';
import { fetcher } from './fetcher';

const getEndpoint = (
  bucketUrl: string,
  pathname: string,
  locale: string,
  menu?: string,
  type?: UrlType,
) =>
  getStaticBucketUrl({
    bucketUrl,
    pathname,
    language: locale.substring(0, 2),
    menu: menu || '',
    type: type || UrlType.PAGE,
  });

/**
 * fetch data from static endpoint like google bucket
 * If fetch is failing an error is thrown
 *
 * @param bucketUrl
 * @param pathname
 * @param locale
 * @param type
 * @returns data from bucket as json
 */
const cmsFetcher = async <T>({
  pathname = '/',
  bucketUrl,
  locale = 'de-DE',
  type = UrlType.PAGE,
  revisionId,
}: {
  pathname?: string | string[];
  bucketUrl?: string;
  locale?: string;
  type?: UrlType;
  revisionId?: string;
}) => {
  if (type === UrlType.PREVIEW) {
    const pathArray = Array.isArray(pathname) ? pathname : pathname.split('/');
    // prepare correct pathName of content-json to request from cms
    const localeByPath = pathArray.splice(0, 1) || locale.substring(0, 2);
    // find and remove the revisionId-path-segment from pathname (was optional added by the middleware)
    const drupalPreviewFetchUrl = `${config.staticContent.apiEndpoints.webappJson
      .replace('<envTld>', process.env.NEXT_PUBLIC_ENVIRONMENT === 'staging' ? 'dev' : 'cloud')
      .replace('<locale>', localeByPath.toString())
      .replace('<path>', pathArray.join('/'))}${revisionId ? `&revisionId=${revisionId}` : ''}`;
    const drupalPreviewFetchHeaders = {
      ...(process.env.EMPDEV_AUTH && { 'empdev-auth': process.env.EMPDEV_AUTH }),
      'User-Agent': `fetch/static-content-app-preview`,
      ...(process.env.DRUPAL_PREVIEW_AUTH && {
        Authorization: `Basic ${process.env.DRUPAL_PREVIEW_AUTH}`,
      }),
    };
    return fetcher<T>(drupalPreviewFetchUrl, undefined, drupalPreviewFetchHeaders);
  }
  if (bucketUrl) {
    // prepare correct pathName of content-json to request from bucket
    const fileName =
      Array.isArray(pathname) && pathname
        ? pathname
            ?.filter((pathSegment: string) => !pathSegment.match(/de|fr|storefront/))
            ?.join('-')
        : pathname || '/';
    // Assemble the final url to be fetched
    const apiCmsUrlStaticFileCms = getStaticBucketUrl({
      bucketUrl,
      pathname: fileName,
      language: locale.substring(0, 2),
      type,
      menu: type === UrlType.MENU ? fileName : undefined,
    });

    return fetcher<T>(apiCmsUrlStaticFileCms);
  }
  return null;
};

export { cmsFetcher, getEndpoint };
