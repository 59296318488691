'use client';

import { type FC } from 'react';

import { DefaultHeader } from './DefaultHeader';
import { MinimalHeader } from './MinimalHeader';
import { PaceId } from './PaceId';
import { SearchHistoryUpdater } from './SearchHistoryUpdater';
import { AbTestEnrollments } from './AbTestEnrollments';
import { DynamicYieldCookie } from './DynamicYieldCookie';

export type HeaderProps = {
  isMinimal?: boolean;
};

export const Header: FC<HeaderProps> = ({ isMinimal }) => (
  <>
    <PaceId />
    <DynamicYieldCookie />
    <SearchHistoryUpdater />
    <AbTestEnrollments />

    {isMinimal ? <MinimalHeader /> : <DefaultHeader />}
  </>
);
