/* eslint-disable react/no-array-index-key */

'use client';

import { useHasNewNavigation } from '../../useHasNewNavigation';
import { ResponsiveFullBleedBackground } from './Internals/ResponsiveFullBleedBackground';

export const ResponsiveNavigationLoading = () => {
  const { desktop: hasNewDesktopNavigation } = useHasNewNavigation();

  return (
    <>
      {/* h-6 is necessary because, for unknown reasons, otherwise the height with 32px content and 8px padding results in 52px total height instead of the intended 48px */}
      <div aria-busy className="h-6 cursor-wait p-1 lg:hidden">
        <div className="icon-emp-burger-text text-color-grey h-4 w-4 animate-pulse" />
      </div>
      {hasNewDesktopNavigation ? (
        <nav aria-busy className="relative max-lg:hidden">
          <ResponsiveFullBleedBackground className="border-color-grey border-y" />
          <div className="flex h-[calc(2.75rem+2px)] items-center">
            {Array.from({ length: 10 }).map((_, index) => (
              <div
                key={index}
                className="bg-color-grey-light text-size-body1 mx-1 h-[1em] flex-1 animate-pulse rounded-sm"
              />
            ))}
          </div>
        </nav>
      ) : (
        <nav aria-busy className="relative max-lg:hidden">
          <ResponsiveFullBleedBackground className="border-color-primary border-b-4" />
          <div className="-mx-1 flex h-5 items-center pb-0.5">
            {Array.from({ length: 10 }).map((_, index) => (
              // font size 18px, but visible only around 13-14px??
              <div
                key={index}
                className="bg-color-grey-light mx-1 h-[1rem] flex-1 animate-pulse rounded-sm xl:h-[1.125rem]"
              />
            ))}
          </div>
        </nav>
      )}
    </>
  );
};
