'use client';

import { type FC } from 'react';
import { useAbTest } from '@packages/shared/src/abtesting/useAbTest/useAbTest';

export const AbTestEnrollments: FC = () => {
  // useAbTest('2024_04_SoFiOn'); // TODO re-add after/during SEARCH-2926, disabled for now because the test was postponed due to missing functionality on the DV
  // note: previously the test used server-side enrollment, but due to issues in SEARCH-3176 and ongoing issues on OVAT we are now using client-side enrollment
  useAbTest(
    process.env.NEXT_PUBLIC_ENVIRONMENT === 'staging' ? '2024_08_Endless_test' : '2024_08_Endless',
  );

  return null;
};
